const $os = require('detectOS');

require('@common/configs/xRebel');

require('@common/configs/jquery/jquery');
require('@common/configs/jquery/showhide');
require('@common/configs/jquery/deferred');
require('@common/configs/jquery/ajax');
require('@common/configs/jquery/transitionPolyfill');

require('@common/configs/i18n');

require('@common/configs/underscore');
require('@common/configs/lodash');

require('@common/configs/froala');

require('@common/configs/backbone/view');

require('@common/configs/marionette/renderer');
require('@common/configs/marionette/view');
require('@common/configs/marionette/region');

require('@common/configs/duetds/config');

// figure out if this is actually needed and if so where to put it so it doesn't break ie11
if ($os.browser !== 'ie') {
  require('@common/configs/cssVarsPonyfill');
}

// Initialize mswjs mock data server in development
if (process.env.enableMockServiceWorker) {
  const { initMockWorker } = require('@common/data/mocks/browser');
  initMockWorker();
}
