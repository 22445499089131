const env = require('env');
const logging = require('logging');
const {
  sendWarnLog
} = require('LoggingService');
const {
  flatten,
  template,
  extend,
  compact,
  isObject,
  isString
} = require('underscore');

const HTMLHelpers = require('@common/libs/helpers/app/HTMLHelpers');

class I18nString {

  constructor(rawString) {
    this._rawString = rawString;
  }

  getString(context = {}) {
    this._stringTemplate = this._stringTemplate || template(this._rawString);

    return this._stringTemplate(context);
  }
}

const processMapping = (target, stringId, ...objects) => {
  return objects.map((object) => {
    Object.entries(object || {}).forEach(([key, value]) => {
      const newStringId = stringId.length === 0 ? key : `${ stringId }.${ key }`;

      if (isObject(value)) {
        if (isString(value.value)) {
          target[newStringId] = new I18nString(value.value);
        } else {
          processMapping(target, newStringId, value);
        }
      } else if (isString(value)) {
        target[newStringId] = new I18nString(value);
      }
    });
  });
};

const getHtmlDirection = (isRtl) => {
  if (isRtl) {
    return 'rtl';
  }

  return 'ltr';
};

const I18n = {
  _locale: null,
  mapping: {},
  bundles: [],
  listeners: [],
  errors: {},

  // Support stringId in dot notation, like 'a.b.c'.
  // Support interpolation via underscore template.
  t(stringId, context = {}) {
    const i18nStringInstance = this.mapping[stringId];

    if (i18nStringInstance == null) {
      this._handleError(stringId, this.errors, `Can't find '${ stringId }' in the strings file.`, {
        error: new Error(`MissingStringKeyException: ${ stringId }`)
      });
      return undefined;
    }

    const data = extend({}, context, {
      numberWithCommas: this.numberWithCommas,
      flattenHTMLToRange(html, max) {
        return HTMLHelpers.summarizeHTMLToRange(HTMLHelpers.stripHtmlInlineElements(html), max);
      },
      summarizeHTMLToRange: HTMLHelpers.summarizeHTMLToRange,
      t: I18n.t.bind(I18n)
    });

    try {
      return i18nStringInstance.getString(data);
    } catch (error) {
      const msg = `Failed to templatize string for stringId '${ stringId }'`;

      this._handleError(stringId, this.errors, msg, { error });

      return undefined;
    }
  },

  hasString(stringId) {
    return (this.mapping[stringId] != null);
  },

  register(mapping) {
    return processMapping(this.mapping, '', mapping);
  },

  listenForLocaleChange(listener) {
    return this.listeners.push(listener);
  },

  clear() {
    this.mapping = {};
  },

  getBrowserLocale() {
    let locale;

    if (navigator) {
      locale = navigator.language || navigator.browserLanguage || navigator.systemLanguage || navigator.userLanguage;
      if (locale) {
        locale = locale.replace('_', '-').replace(/\W/, '-');
      }
    }

    return locale.toUpperCase() || 'EN';
  },

  registerBundle(bundle) {
    return this.bundles.push(bundle);
  },

  setLocale(locale, callbacks = {}) {
    const supportedLocales = env.settings.supportedLocales;
    const {
      success = () => {},
      failure = () => {},
      always = () => {}
    } = callbacks;

    const paths = [];

    // Find the best fit in supported locales
    const parts = locale.toUpperCase().split('-');

    const oldLocale = this._locale;

    for (let i = 0, end = parts.length, asc = 0 <= end; asc ? i < end : i > end; asc ? i++ : i--) {
      const current = parts.slice(0, Number(i) + 1 || undefined).join('-');
      if (supportedLocales.includes(current)) {
        // We make a special exception here since everything elses lower case for now
        this._locale = current.toLowerCase();
      }
    }

    const hasLocaleChanged = oldLocale !== this._locale;

    $('html').attr({
      lang: this._locale,
      'data-language': this._locale
    });

    const onSuccess = (strings) => {
      I18n.clear();

      const strs = compact(flatten(flatten(strings)));

      strs.forEach((str) => {
        I18n.register(str.default || str);
      });

      $('html').attr('dir', getHtmlDirection(I18n.isCurrentLanguageRtl()));

      // Call all post hooks
      for (const bundle of Array.from(this.bundles)) {
        bundle.onPostLoad(this._locale);
      }

      if (hasLocaleChanged) {
        for (const listener of this.listeners) {
          listener(I18n.getLocale());
        }
      }

      // All done
      success();
      always();
    };

    const onFailure = () => {
      logging.error(`Attempted to load the following paths \n${ paths.join('\n') } \nbut some of the files failed to load! Trying to continue anyway... ignoring as if nothing happened`);

      // Call the listeners to let them know we're done with what we can...
      if (hasLocaleChanged) {
        for (const listener of this.listeners) {
          listener();
        }
      }

      // All done
      failure();
      always();
    };

    Promise.map(this.bundles, (bundle) => {
      let loadPromises = bundle.load(this._locale);
      loadPromises = [].concat(loadPromises);
      return Promise.all(loadPromises);
    }).then(onSuccess, onFailure);
  },

  getLocale() {
    return (this._locale != null ? this._locale.toUpperCase() : undefined);
  },

  languageNameNative(langCode) {
    return this.t(`nativeLanguageNames.${ langCode }`);
  },

  languageNameFromCurrentLocale(langCode = '') {
    const code = langCode.replace(/-|_/g, '');
    return this.t(`languages.${ code.toUpperCase() }`);
  },

  isRtlLanguage(langCode) {
    return ['AR', 'FA', 'HE', 'KU', 'PS', 'SD', 'UG', 'UR', 'YI'].includes(langCode);
  },

  isCurrentLanguageRtl() {
    return this.isRtlLanguage(this.getLocale());
  },

  numberWithCommas(x) {
    if (x != null) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    return 0;
  },

  _handleError(stringId, errors, msg, logData = {}) {
    let attempt = 0;

    const sendError = () => {
      // check if this error has already been sent up
      if (errors[stringId] == null) {
        sendWarnLog({
          logData: Object.assign({
            logMessage: msg,
            i18n: {
              stringId,
              locale: this.getLocale()
            }
          }, logData)
        });

        logging.error(msg);

        errors[stringId] = true;
      } else if (attempt < 3) {
        attempt++;
        setTimeout(sendError, 1000);
      }
    };

    sendError();
  }
};

module.exports = I18n;
