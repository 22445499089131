const _ = require('underscore');
const I18n = require('@common/libs/I18n');
const FroalaConfig = require('@common/libs/froala/FroalaConfig');
const FroalaLocaleBundle = require('@common/libs/froala/FroalaLocaleBundle');
const BrowserHelpers = require('@common/libs/helpers/app/BrowserHelpers');
const FileHelpers = require('@common/libs/file/FileHelpers');
const HTMLHelpers = require('@common/libs/helpers/app/HTMLHelpers');
const UrlHelpers = require('@common/libs/helpers/app/UrlHelpers');

/**
 * Register our custom video uploader (always), image (optional), and code format (optional) plugins if necessary for
 * the given FroalaEditor instance
 * @param {Object} FroalaEditor - The current Froala Editor library that we want to register plugins for.
 * @param {Object} [options={}] - Custom configuration options
 * @param {function} [options.onInsertImage] - Function to be called after an image is inserted. If this is not
 *  provided, we do not register the custom image upload plugin
 * @param {function} [options.codeFormat] - Function to be called when attempting to format text as code. If this is not
 *  provided, we do not register the custom code formatting plugin
 */
const registerFroalaCustomPlugins = (FroalaEditor, options = {}) => {
  const {
    onInsertImage,
    codeFormat
  } = options;
  if (codeFormat) {
    FroalaEditor.RegisterCommand('code', {
      title: 'Code Format',
      focus: true,
      undo: true,
      refreshAfterCallback: true,
      callback: codeFormat
    });
    FroalaEditor.RegisterShortcut(89, 'code', '', 'Y', false, false);
  }

  if (onInsertImage) {
    FroalaEditor.RegisterCommand('uploadImage', {
      title: 'Insert Image',
      icon: 'insertImage',
      undo: false,
      focus: true,
      showOnMobile: true,
      refreshAfterCallback: true,
      callback: onInsertImage
    });
  }

  _attachVideoUploadPlugin(FroalaEditor);
};

/**
 * Creates and attaches our custom video uploader plugin alongside the existing videoByURL and videoEmbed plugins
 * @param {Object} FroalaEditor - The current Froala Editor library that we want to register plugins for.
 */
const _attachVideoUploadPlugin = (FroalaEditor) => {
  FroalaEditor.POPUP_TEMPLATES['axVideoUpload.popup'] = '[_BUTTONS_][_UPLOAD_]';
  Object.assign(FroalaEditor.DEFAULTS, {
    popupButtons: ['videoByURL', 'axVideoUpload', 'videoEmbed']
  });

  FroalaEditor.PLUGINS.axVideoUpload = (editor) => {
    // Create custom popup.
    const initPopup = function () {
      // Popup buttons.
      let popupButtons = '';

      // Create the list of buttons.
      popupButtons += '<div class="fr-buttons">';
      popupButtons += editor.button.buildList(editor.opts.popupButtons);
      popupButtons += '</div>';

      const uploadForm = `<div class="fr-layer fr-active ax-upload-layer">
        <div class="pad-med video-upload-form">
          <p>${ I18n.t('selfDirected.rteView.languageSelect') }</p>

        <form id="video-form">
          <select id="select-list" data-options="languages" data-field="language"></select>
        </form>

        <button id="video-upload-button" class="ax-button ax-button--branded disabled video-upload-button" type="button" disabled>
          ${ I18n.t('selfDirected.rteView.uploadVideo') }
        </button>
      </div>
    </div>
      `;

      // Load popup template.
      const template = {
        buttons: popupButtons,
        upload: uploadForm
      };

      // Create popup.
      const $popup = editor.popups.create('axVideoUpload.popup', template);

      // Assign hide handler.
      editor.popups.onHide('axVideoUpload.popup', hidePopup);
      return $popup;
    };

    // Show the popup
    const showPopup = function () {
      // Get the popup object defined above.
      let $popup = editor.popups.get('axVideoUpload.popup');

      // If popup doesn't exist then create it.
      // To improve performance it is best to create the popup when it is first needed
      // and not when the editor is initialized.
      if (!$popup) {
        $popup = initPopup();
      }

      // Set the editor toolbar as the popup's container.
      editor.popups.setContainer('axVideoUpload.popup', editor.$tb);

      // This will trigger the refresh event assigned to the popup.
      // editor.popups.refresh('axVideoUpload.popup');

      // This custom popup is opened by pressing a button from the editor's toolbar.
      // Get the button's object in order to place the popup relative to it.
      const $btn = editor.$tb.find('.fr-command[data-cmd="insertVideo"]');

      // Set the popup's position.
      const left = $btn.offset().left;
      const top = $btn.offset().top + (editor.opts.toolbarBottom ? 10 : $btn.outerHeight() - 10);

      editor.$tb.find('.fr-video-by-url-layer, .fr-video-embed-layer').removeClass('fr-active');
      editor.$tb.find('.ax-upload-layer').addClass('fr-active');

      // Show the custom popup.
      // The button's outerHeight is required in case the popup needs to be displayed above it.
      editor.popups.show('axVideoUpload.popup', left, top, $btn.outerHeight());
      editor.button.bulkRefresh();
    };

    // Hide the custom popup.
    const hidePopup = function () {
      editor.$tb.find('.ax-upload-layer').removeClass('fr-active');
      editor.popups.hide('axVideoUpload.popup');
      if (editor.$tb.find('.fr-video-by-url-layer.fr-active, .fr-video-embed-layer.fr-active').length === 0) {
        editor.$tb.find('.fr-video-by-url-layer').addClass('fr-active'); //Reset the insert button layers before closing
        editor.button.bulkRefresh(); // Avoid a corner case where the wrong button could be active
      }
    };

    // Methods visible outside the plugin.
    return {
      showPopup: showPopup,
      hidePopup: hidePopup
    };
  };

  FroalaEditor.DefineIcon('upload', {
    NAME: 'upload',
    SVG_KEY: 'upload'
  });
  // Define an icon and command for the button that opens the custom popup.
  FroalaEditor.RegisterCommand('axVideoUpload', {
    title: 'Upload Video',
    icon: 'upload',
    undo: false,
    focus: false,
    plugin: 'axVideoUpload',
    callback: function () {
      const $popup = this.popups.get('axVideoUpload.popup');
      if ($popup) {
        $popup.find('fr-layer').removeClass('.fr-active');
      }
      this.axVideoUpload.showPopup();
    },
    refresh: function ($btn) {
      const $popup = this.popups.get('axVideoUpload.popup');

      if ($popup && $popup.find('.ax-upload-layer').hasClass('fr-active')) {
        $btn.addClass('fr-active').attr('aria-pressed', true);
      }
    }
  });
};

/**
 * Map over the config object and run the translations for the current locale.
 * This will ensure the string overrides are working in the editor.
 * @returns {Object} String override object for the current locale
 */
const buildStringOverrides = () => {
  return _.mapObject(FroalaConfig.stringOverrides, (v) => {
    return I18n.t(v);
  });
};

/**
 * Load the appropriate language of Froala based on our locale bundle settings.
 * @param {Object} FroalaEditor - The current Froala Editor library that we want to set the language for
 * @param {function} callback - The callback to run after loading the language. Usually involves registering plugins and such.
 */
const loadFroalaLanguage = (FroalaEditor, callback) => {
  const locale = FroalaLocaleBundle.getLocale(I18n.getLocale());

  // This will override strings for any language (even if that language is not yet defined!) provided we have a translation.
  // This is required to be part of the callback, or when loading a lang file it will be overwritten.
  const overrideStringsThenCallback = () => {
    _.extend(FroalaEditor.LANGUAGE[locale].translation, buildStringOverrides());
    callback();
  };

  const loadPrms = FroalaLocaleBundle.load(locale);

  if (loadPrms != null) {
    // loads the appropriate language file, overrides the strings, and then runs the callback function.
    loadPrms.then(overrideStringsThenCallback);
  } else {
  // If we're not loading a language, we assume we're using english. The editor's base language is english, so they
  // don't include translations by default. We will want to include our overrides as a pseudo-translation.
    FroalaEditor.LANGUAGE['en'] = {translation: {}};
    overrideStringsThenCallback();
  }
};

/**
 * Initialize a new instance of the Froala Editor
 * @param {Object} FroalaEditor - The current Froala Editor library that we are using to create the instance
 * @param {Object} [options={}] - Optional configuration parameters
 * @param {function} [options.afterInit=() => {}] - Optional callback function to be run after editor initialization
 * @param {Object} [mediaAttachmentsEnabled={images: false, videos:false}] - Which media attachments are enabled?
 * @param {Array} [options.linkList=[]] - Optional array of links to add to the quicklink (magnifying glass) button in
 *  the "add link" popup
 * @param {function} [options.onEditorTextChange=() => {}] - Optional callback to run each time text is updated
 *  in the editor
 * @param {boolean} [options.imageUpload=true] - If false, the image upload plugin will be disabled. This is really only
 * useful to disable drag and drop uploads of images. We have our own implementation for image uploads that is
 * unaffected by this setting (see registerFroalaCustomPlugins method and onInsertImage option).
 * @param {function} [options.onImageDisplay=() => {}] - Optional callback to run when an image is displayed
 * @param {function} [options.onImageSetAlt=() => {}] - Optional callback to run when an image's alt text is updated
 * @param {function} [options.onImageRemoved=() => {}] - Optional callback to run when an image is removed
 * @param {function} [options.onLinkBeforeInsert=() => {}] - Optional callback to run before a link is inserted
 * @param {function|null} [options.onLinkOpen=null] - Optional callback to run when opening a link
 * @param {function} [options.onShowVideoUploadPopup=() => {}] - Optional callback to run when the video upload
 *  popup is shown
 * @param {string|null} [options.scrollableContainer='body'] - Optional selector for the editor's
 *  closest scrollable ancestor. Required if dealing with nested fixed height/scrollable containers
 * @param {boolean} [options.hideScrollableContainerOnMore=true] - if true, the scrollable container will be hidden when
 *  the "more" button is activated
 * @param {boolean} [options.toolbarsEnabled=true] - If true toolbars should be displayed
 * @param {boolean} [options.extendedToolbars=false] - If true toolbars should have extended options
 *  with additional plugins
 * @param {Object} [flashChannels={warning: window.app.layout.flash.warning}] - Object containing functions for displaying
 * error/warning/success toasts. Currently only warning is used, so it is the only supplied option.
 * @param {function} [flashChannels.warning=app.layout.flash.warning] - Function for displaying a warning toast.
 */
const initializeFroalaEditor = (FroalaEditor, options = {}) => {
  const {
    afterInit = () => {}, //default to no-ops
    mediaAttachmentsEnabled = {
      images: false,
      videos: false
    },
    linkList = [],
    onEditorTextChange = () => {},
    imageUpload = true,
    onImageDisplay = () => {},
    onImageSetAlt = () => {},
    onImageRemoved = () => {},
    onLinkBeforeInsert = () => {},
    onLinkOpen = null,
    onShowVideoUploadPopup = () => {},
    scrollableContainer = null,
    hideScrollableContainerOnMore = true,
    toolbarsEnabled = true,
    customEvents = {},
    customToolbarButtons = {},
    wordPasteEnabled = true,
    htmlAllowedTags = null, // use Froala defaults
    htmlAllowedAttrs = null, // use Froala defaults
    extendedToolbars = false,
    toolbarSticky = true,
    flashChannels = {warning: window.app.layout.flash.warning}
  } = options;

  // The list of enabled plugins for the current instance.
  const pluginsEnabled = [
    'align', 'colors', 'fontFamily', 'fontSize', 'fullscreen', 'link',
    'lists', 'paragraphFormat', 'quote', 'save', 'table', 'url'
  ];

  // Image edit buttons that we can use in Discover/anywhere with standard toolbars
  const imageEditButtons = ['imageCaption', 'imageRemove', '|', 'imageLink', 'linkOpen', 'linkEdit', 'linkRemove', '-', 'imageStyle', 'imageAlt', 'imageSize'];

  // Enable the image plugin if we expect image media attachments
  if (mediaAttachmentsEnabled.images) {
    pluginsEnabled.push('image');
  }
  // Enable the video plugin and our custom video uploader if we expect video attachments
  if (mediaAttachmentsEnabled.videos) {
    pluginsEnabled.push('video');
    pluginsEnabled.push('axVideoUpload');
  }
  // Enable the emoticon plugin when using extended toolbars, and add additional image editing options
  if (extendedToolbars) {
    pluginsEnabled.push('emoticons');
    imageEditButtons.splice(1, 0, 'imageDisplay', 'imageAlign');
  }
  // Disable the wordPaste plugin when using timeline posts
  if (wordPasteEnabled) {
    pluginsEnabled.push('wordPaste');
  }

  const froalaConfigObject = {
    tableStyles: {
      'fr-table-fullwidth': 'Width Expanded',
      'fr-dashed-borders': 'Dashed Borders',
      'fr-alternate-rows': 'Alternate Rows'
    },
    enter: FroalaEditor.ENTER_DIV,
    events: {
      'video.linkError': (link) => {
        if (UrlHelpers.isUrl(link)) {
          const scrubbedLink = link.replace(/[^\w\s?=&:;/_.,#~$+%\\-]/gi, '');
          const iframe = `<iframe width="640" height="360" src="${ scrubbedLink }" frameborder="0" allowfullscreen="" class="fr-draggable" sandbox="allow-scripts allow-same-origin"></iframe>`;
          editor.video.insert(iframe);
        }
      },
      'commands.before': (cmd) => {
        if (cmd === 'linkOpen' && onLinkOpen) {
          return onLinkOpen(editor.link.get());
        }

        return true;
      },
      'commands.after': (cmd, param1) => {
        switch (cmd) {
          case 'imageSetAlt':
            onImageSetAlt();
            break;
          case 'imageDisplay':
            onImageDisplay(param1);
            break;
          case 'insertLink':
          case 'imageLink':
          case 'linkEdit':
          case 'linkList':
            if (linkList.length > 0) { // Only for those editors where we can create links to an existing attachment
            // We want to ensure data-fields are hidden from the user
              editor.$('.fr-link-insert-layer').find('input[name="data-media-id"]')
                .parent()
                .css({display: 'none'});
              editor.$('.fr-link-insert-layer').find(`input[name="href"]`)
                .parent()
                .css({display: ''});
              editor.$('.fr-link-insert-layer').find(`input[name="href"]`)
                .filter((element) => {
                  return element.value.indexOf(FileHelpers.DOWNLOAD_FILE_PLACEHOLDER) > -1;
                })
                .parent()
                .css({display: 'none'});
            }
            break;
          case 'moreRich':
          case 'moreMisc':
          case 'moreParagraph':
          case 'moreText':
            // Only consistent way to trigger the resize at the appropriate time
            if (scrollableContainer && hideScrollableContainerOnMore) {
              $(scrollableContainer).css({overflow: 'hidden'});
            }
            setTimeout(() => {
              BrowserHelpers.triggerResize(true);
              if (scrollableContainer && hideScrollableContainerOnMore) {
                $(scrollableContainer).css({overflow: ''});
              }
            }, 480);
            break;
          default:
            break; //no-op
        }
      },
      'link.beforeInsert': onLinkBeforeInsert,
      keyup: onEditorTextChange, //contentChanged event could be used instead, however, it is async & very slow
      'image.removed': onImageRemoved,
      // The following is incredibly fragile and dependent on Microsoft Office 365's copy/paste functionality.
      // It displays a warning that some styling may have changed when pasting from Office 365
      'paste.beforeCleanup': (html) => {
        let htmlCopy = html;
        const isWordDoc = html.match(/(class="?Mso|class='?Mso|class="?Xl|class='?Xl|class=Xl|style="[^"]*\bmso-|style='[^']*\bmso-|w:WordDocument|LibreOffice)/gi);

        if (BrowserHelpers.isIE() && (
          $(html).find('.BCX0').length > 0 //Magic classname is added from Office 365 word paste and not covered by the WP plugin
          // Following is used to check if pasting from a wordDoc; paste.wordPaste event doesn't seem to trigger regardless, so we'll check it here.
          || isWordDoc)) {
          flashChannels.warning(I18n.t('froala.wordPaste.warning'));
        }

        if (!isWordDoc) {
          htmlCopy = HTMLHelpers.stripHtmlForDisplay(html);
        }

        return htmlCopy;
      },
      'popups.show.axVideoUpload.popup': onShowVideoUploadPopup,
      'html.beforeGet'() {
        const imgs = Array.from(this.el.querySelectorAll('img'));

        imgs.forEach((image) => {
          const $image = $(image);
          const width = $image.width();
          const height = $image.height();
          $image.css({
            width,
            height
          });
        });
      }
    },
    imageEditButtons,
    imageUpload,
    language: FroalaLocaleBundle.getLocale(I18n.getLocale()) || 'en', // Default to English if we don't have a locale
    linkAttributes: linkList.length > 0 ? { // Hidden data-media-id attribute for linking to existing attachments
      'data-media-id': 'data-media-id'
    } : {},
    linkInsertButtons: linkList.length > 0 ? ['linkBack', '|', 'linkList'] : ['linkBack', '|'],
    linkList: linkList,
    pluginsEnabled,
    scrollableContainer,
    toolbarButtons: customToolbarButtons,
    toolbarSticky
  };

  // htmlAllowedTags must not be added to the config object unless it is overriding the default. It was probably
  // passed in as null, but in the case of Posts, it's an array of allowed HTML tags.
  if (htmlAllowedTags) {
    froalaConfigObject.htmlAllowedTags = htmlAllowedTags;
  }

  if (htmlAllowedAttrs) {
    froalaConfigObject.htmlAllowedAttrs = htmlAllowedAttrs;
  }

  if (customEvents != null) {
    Object.assign(froalaConfigObject.events, customEvents);
  }

  const config = FroalaConfig.initConfig(froalaConfigObject, mediaAttachmentsEnabled, toolbarsEnabled);

  // Create the editor instance using the above config
  const editor = new FroalaEditor('.froala-editor', config, () => {
    //Once the editor initializes, do the following:
    afterInit(editor);
  });
};

module.exports = {
  loadFroalaLanguage,
  registerFroalaCustomPlugins,
  initializeFroalaEditor
};
