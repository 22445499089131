import AccessibleTooltipButton from '@common/components/accessibleTooltip/AccessibleTooltipButton';
import {
  AxStack,
  type IconNames
} from '@common/modules/react/themes/components/';
import { fieldRequiredAsteriskStyles } from '@common/modules/react/themes/components/custom/atoms/FieldRequiredAsterisk';
import {
  InputLabel as MuiInputLabel,
  styled,
  useTheme,
  type InputLabelProps as MuiInputLabelProps,
  type SxProps,
  type Theme
} from '@mui/material';
import {
  forwardRef,
  type Ref
} from 'react';

type ToOmit = 'color' |
  'disableAnimation' |
  'filled' |
  'shrink' |
  'size' |
  'variant';

type AxInputLabelProps = Omit<MuiInputLabelProps, ToOmit> & {
  /**
   * Props for the tooltip
   */
  tooltipProps?: {
    /**
     * Tooltip text to display
     */
    title?: string | React.ReactNode
    /**
     * Icon to display in the tooltip
     */
    iconName?: IconNames
    /**
     * Custom styles for the tooltip icon
     */
    sx?: SxProps<Theme>,
  }
};

const styledOptions = {
  name: 'AxInputLabel'
};

const StyledAxInputLabel = styled(MuiInputLabel, styledOptions)<AxInputLabelProps>(({ theme }) => {
  return {
    color: theme.uiKit.colorGrey70,
    lineHeight: theme.uiKit.lineHeightS,
    // TODO: Remove !important. This is a temporary fix for the font size issue caused by ax-font.less l:83
    fontSize: `${ theme.uiKit.fontSizeXS } !important`,
    // TODO: Remove !important. This is a temporary fix for the margin issue caused MUI-Label
    margin: `0 !important`,
    '.MuiFormLabel-asterisk': fieldRequiredAsteriskStyles(theme),
    '&.Mui-error': {
      color: theme.uiKit.colorRed60
    }
  };
});

export const AxInputLabel = forwardRef(({
  tooltipProps,
  children,
  sx,
  ...otherProps
}: AxInputLabelProps, ref: Ref<HTMLLabelElement>) => {
  
  const theme = useTheme();
  
  const inputLabelProps: AxInputLabelProps = {
    ...otherProps
  };

  if (!tooltipProps) {
    return <StyledAxInputLabel { ...inputLabelProps } sx={ sx } ref={ ref } >
      { children }
    </StyledAxInputLabel>;
  }

  return (
    <AxStack
      direction='row'
      alignItems='center'
      marginBottom={ theme.uiKit.spacingXS }
      sx={ sx }
    >
      <StyledAxInputLabel { ...inputLabelProps } ref={ ref } >
        { children }
      </StyledAxInputLabel>
      <AccessibleTooltipButton
        tooltipProps={{
          title: tooltipProps.title
        }}
        buttonProps={{
          className: tooltipProps.iconName ?? 'icon-info_sign',
          sx: tooltipProps.sx
        }}
      />
    </AxStack>
  );
});

export default AxInputLabel;
