const _ = require('underscore');
const Marionette = require('Marionette');
const ImageLoader = require('@common/libs/ImageLoader');
const ImageHelpers = require('@common/libs/helpers/app/ImageHelpers');
const BrowserHelpers = require('@common/libs/helpers/app/BrowserHelpers');
const FileFactory = require('@common/libs/file/FileFactory');
const MediaFactory = require('@common/libs/file/MediaFactory');

const loadedImagePaths = new Set();

class ImageViewer extends Marionette.ItemView {
  initialize(options = {}) {
    ({
      media: this.media,
      maxWidth: this.maxWidth,
      maxHeight: this.maxHeight,
      displayWidth: this.displayWidth,
      displayHeight: this.displayHeight,
      fitToScreen: this.fitToScreen = false,
      fallbackAlt: this.fallbackAlt,
      imgClass: this.imgClass,
      imgStyles: this.imgStyles,
      keepExistingChildNodes: this.keepExistingChildNodes,
      replaceElement: this.replaceElement = false,
      ariaHidden: this.ariaHidden = false
    } = options);
    this.template = false;

  }

  render() {
    this._$image = $('<img/>');

    if (!this.keepExistingChildNodes) {
      this.$el.empty();
    }
    if (this.replaceElement) {
      this.$el.replaceWith(this._$image);
    } else {
      this.$el.prepend(this._$image);
    }

    const maxDimensions = this.getMaxDimensions();

    const imageFile = this._getImageFile(maxDimensions);

    if (this.imgStyles) {
      this._$image.attr('style', this.imgStyles);
    }
    if (this.media.altText) {
      this._$image.attr('alt', this.media.altText.preferred);
    } else if (this.fallbackAlt) {
      this._$image.attr('alt', this.fallbackAlt);
    } else if (this.media.originalFile) {
      this._$image.attr('alt', '');
    }

    this._$image.attr('aria-hidden', this.ariaHidden);
    this._$image.css('max-width', '100%');
    this._$image.css('width', this.displayWidth || ''); // If the image has been resized, force this width.
    this._$image.css('height', this.displayHeight || ''); // If the image has been resized, force this height.
    this._$image.css('visibility', loadedImagePaths.has(imageFile.path) ? 'visible' : 'hidden');
    this._$image.attr('data-media-id', this.media.id);
    this._$image.data(maxDimensions);
    this._$image.addClass(this.imgClass);

    this._$image.attr('src', imageFile.path);

    this._attemptLoadPath(imageFile.path, maxDimensions);
    
    return this;
  }

  resize() {
    // Determine the bigger of:
    // 1. area we have to display the image (`getMaxDimensions`)
    // 2. largest previous size of the image (`data`)
    // and use this to call `getBiggestImageFileBySize`
    let maxDimensions = this.getMaxDimensions();

    const maxWidth = this._$image.data('maxWidth');
    const maxHeight = this._$image.data('maxHeight');

    if (maxWidth > maxDimensions.maxWidth || maxHeight > maxDimensions.maxHeight) {
      maxDimensions = {};
      if (maxWidth != null) {
        maxDimensions.maxWidth = maxWidth;
      }
      if (maxHeight != null) {
        maxDimensions.maxHeight = maxHeight;
      }
    }

    const imageFile = this._getImageFile(maxDimensions);

    const imageSource = this._$image.attr('src') || '';
    const sameImage = imageSource.indexOf(imageFile.path) > -1;
    if (sameImage) {
      // If it's the same image then we need to determine if the image's current
      // size is bigger than it's previous size and update the `data` accordingly
      // mainly to keep an accurate watermark of the largest size for this `path`
      const imageWidth = this._$image.width();
      const imageHeight = this._$image.height();

      if (imageWidth > maxWidth || imageHeight > maxHeight) {
        return this._$image.data({
          maxWidth: imageWidth,
          maxHeight: imageHeight
        });
      }
    } else {
      return this._attemptLoadPath(imageFile.path, maxDimensions);
    }
    return undefined;
  }

  getMaxDimensions() {
    if (this.fitToScreen) {
      return {
        maxWidth: BrowserHelpers.windowWidth(),
        maxHeight: BrowserHelpers.windowHeight()
      };
    }

    return ImageHelpers.getMaxDimensions(this.$el.width(), this.$el.height(), this.maxWidth, this.maxHeight);
  }

  _getImageFile(maxDimensions) {
    const biggestImageFile = ImageHelpers.getBiggestImageFileBySize(this.media.sizes, maxDimensions)
    if (biggestImageFile != null) {
      return biggestImageFile;
    }

    return this.media.originalFile;
  }

  _mediaToJSON(mediaModel) {
    const json = mediaModel.toJSON();
    if (json.originalFile && _.isFunction(json.originalFile.toJSON)) {
      json.originalFile = json.originalFile.toJSON();
    }

    return json;
  }

  _refetchAndAttemptLoad(maxDimensions) {
    const mediaModel = MediaFactory.createMediaFromJSON(FileFactory, this.media);
    //flatten originalFile to JSON so it will get replaced if we get an updated definition from the server (see _parsedOriginalFile in MediaModel)
    mediaModel.set('originalFile', mediaModel.getFile().toJSON());
    return mediaModel.fetch({
      showSpinner: false,
      success: (model) => {
        this.media = this._mediaToJSON(model);
        const imageFile = this._getImageFile(maxDimensions);
        return this._attemptLoadPath(imageFile.path, maxDimensions, { refetchOnFail: false });
      }
    });
  }

  _attemptLoadPath(path, maxDimensions, options = { refetchOnFail: true }) {
    return ImageLoader.load(path)
      .fail((e) => {
        if (options.refetchOnFail) {
          return this._refetchAndAttemptLoad(maxDimensions);
        }

        return this.trigger('image:error', e);
      })
      .always(() => {
        return this.trigger('image:complete');
      })
      .done((e, image) => {
        const $image = $(image);

        if (this.imgStyles) {
          $image.attr('style', this.imgStyles);
        }
        if (this.media.altText) {
          $image.attr('alt', this.media.altText.preferred);
        } else if (this.fallbackAlt) {
          $image.attr('alt', this.fallbackAlt);
        } else if (this.media.originalFile) {
          $image.attr('alt', '');
        }

        $image.attr('aria-hidden', this.ariaHidden);
        $image.css('max-width', '100%');
        $image.css('width', this.displayWidth || ''); // If the image has been resized, force this width.
        $image.css('height', this.displayHeight || ''); // If the image has been resized, force this height.
        $image.css('visibility', 'visible');
        $image.attr('data-media-id', this.media.id);
        $image.data(maxDimensions);
        $image.addClass(this.imgClass);

        // Replace the reference as well
        this._$image.replaceWith($image);
        this._$image = $image;

        loadedImagePaths.add($image.attr('src'));

        return _.defer(() => {
          return this.trigger('image:loaded', image);
        });
      });
  }
}

module.exports = ImageViewer;
