const { LayoutView } = require('Marionette');
const UIKit = require('@training/widgets/UIKit');

const I18n = require('@common/libs/I18n');

const Page = require('@common/components/discover/models/Page');
const PageType = require('@common/components/discover/enums/PageType');
const PageFactory = require('@common/components/discover/factories/PageFactory');

const PostCreateModal = require('@training/apps/timeline/PostCreateModal');
const CreatePostAccessibleModalView = require('@training/apps/timeline/CreatePostAccessibleModalView');

class CommunityTimelinePostButton extends LayoutView {

  initialize(options = {}) {
    ({
      community: this.community,
      timelineCollection: this.timelineCollection,
      isBrowsingCommunity: this.isBrowsingCommunity // optional argument
    } = options);
  }

  getTemplate() {
    return `
    <div class="button-content-wrapper ax-grid ax-grid--no-gutter ax-grid--justify-content-center ax-grid--align-items-center">
      <div class="profile-icon-region ax-grid__col--auto-size" aria-hidden="true"></div>
      <div class="button-text"><%- t('comms.posts.startPost') %></div>
      <div class="icon-image ax-grid__col--1"></div>
    </div>`;
  }

  tagName() {
    return 'button';
  }

  className() {
    return 'create-post-button full-width card card--interactive';
  }

  attributes() {
    return {
      'aria-label': I18n.t('comms.posts.startPostAria')
    };
  }

  events() {
    return {
      click: this._showCreatePostModal
    };
  }

  regions() {
    return {
      profileIconRegion: '.profile-icon-region'
    };
  }

  _showCreatePostModal() {
    const modalView = new CreatePostAccessibleModalView({
      id: 'modalview',
      className: 'modal create-post-modal'
    });

    const pageType = PageType.POST;
    const page = new Page();
    page.set('type', pageType);

    const userLanguage = window.apps.auth.session.user.get('language');

    const pageFactory = new PageFactory();
    const typedPage = pageFactory.getTypedPage(page);

    typedPage.set('currentVersion', {
      richContent: {
        media: []
      }
    });
    typedPage.set('language', userLanguage);

    // Create a copy of the Community Object to prevent data manipulation to the original object used in parent views/controllers
    if (this.community) {
      this.communityCopy = this.community.clone();
    }

    const modalChildView = new PostCreateModal({
      typedPage,
      isEditing: false,
      community: this.communityCopy,
      isBrowsingCommunity: this.isBrowsingCommunity
    });

    window.app.layout.presentModal(modalView, { closeClick: false });
    modalView.setSubviewIn(modalChildView, { transition: UIKit.View.Transitions.NONE });

    this.listenTo(modalChildView, 'post:saved', () => {
      this.timelineCollection.fetch();
    });
  }
}

module.exports = CommunityTimelinePostButton;
