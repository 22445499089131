const ViewController = require('@common/libs/UI/controllers/ViewController');
const ViewControllerFactory = require('@common/libs/UI/controllers/ViewControllerFactory');
const { ItemView } = require('backbone.marionette');
const ImageViewer = require('@common/components/image/ImageViewer');

const ImageViewerFactory = {

  /*
    options:
      media (JSON) - Required
      fitToScreen (bool) - Optional
      maxWidth (int: pixels) - Optional
      maxHeight (int: pixels) - Optional
      displayWidth (string: px or %) - Optional
      displayHeight (string: px or %) - Optional
      fallbackAlt (string) - Optional (default alt-text if none provided)
      imgClass (string) - Optional (default class to apply to img)
      keepExistingChildNodes (bool) - Optional
      ariaHidden (bool) - Optional (defaults to false)
  */
  createViewerInstance(options = {}) {
    if (options.media == null) {
      return undefined;
    }
    options.el = options.$el;
    return new ImageViewer(options);
  },

  createViewerWithZoom(options = {}) {
    const {
      $el,
      media,
      zoomHandler,
      ...viewerOptions
    } = options;

    const $img = $($el);

    // Linked imgs should not zoom
    if ($el !== null && !$img.parent().is('a')) {
      $img.wrap('<div><div class="image-wrapper"></div></div>');

      const wrapper = ViewControllerFactory.createLegacyView({
        ViewController: ViewController,
        viewDefinition: {
          ViewClass: ItemView,
          template: false,
          className: 'image-wrapper',
          el: $img.parent().parent()[0],
          behaviors: {
            ZoomOverlay: {}
          }
        },
        delegateEvents: {
          'view:image:clicked': () => {
            zoomHandler?.(media);
          }
        }
      });

      wrapper.render();

      return ImageViewerFactory.createViewerInstance({
        ...viewerOptions,
        media,
        $el: $img
      });
    }

    return ImageViewerFactory.createViewerInstance({
      ...viewerOptions,
      media,
      $el
    });
  }
};

module.exports = ImageViewerFactory;
