import I18n from '@common/libs/I18n';
import { AxTypography } from '@common/modules/react/themes/components';
import type { Theme } from '@mui/material';

export default function FieldRequiredAsterisk(): JSX.Element {
  return <AxTypography
    title={ I18n.t('general.required') }
    component = 'span'
    sx = { (theme) => {
      return fieldRequiredAsteriskStyles(theme);
    } }
  >&nbsp;*</AxTypography>;
}

export function fieldRequiredAsteriskStyles(theme: Theme) {
  return {
    fontWeight: theme.uiKit.fontWeightBold,
    color: theme.uiKit.colorSemanticNegative
  };
}
